import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class BenefitsOf extends React.Component {

  render() {
    return (

      <section className="process-of pax-process pt-100 mb-0">
          <div className="gray-bg">
            <div className="container"> 
                <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits of</span> Using Our Paxful Clone
                </h2>
                <div className="d-lg-flex add-one">
                <div className="square" >
                    <h3>Protected Transactions</h3>
                    <p className="pharagraph">
                    Leverage the cutting-edge security features of our Paxful clone software to level up your peer-to-peer transactions to be executed instantly and securely.
                    </p>
                </div>
                <div className="square">
                    <h3>Next-Gen Technology Stacks</h3>
                    <p className="pharagraph">
                    We provide next-generation technology stacks to design and develop a futuristic P2P crypto exchange similar to Paxful enabled with competitive edges.
                    </p>
                </div>
                <div className="square" >
                    <h3>Quicker Processing</h3>
                    <p className="pharagraph">
                    With facilitated add posting and a quicker order-matching engine, our Paxful exchange clone helps you provide almost instant trade execution to the traders.
                    </p>
                </div>
                </div>
                <div className="d-lg-flex add-two">
                <div className="square" >
                    <h3>Escrow Protection</h3>
                    <p className="pharagraph">
                    Keep your users relaxed from the worries of fund handling with a trustworthy fund handling mechanism. Our Paxful-like exchange has an efficient escrow system that manages the funds till the transaction is clear for both parties.
                    </p>
                </div>
                <div className="square" >
                    <h3>High Return on Investment</h3>
                    <p className="pharagraph">
                    Our Paxful clone is capable of supporting your diverse service offerings. Thus you can make a profit from fees associated with various service offerings while ensuring a higher return on your investment.
                    </p>
                </div>
                
                </div>
                <div className="mt-3 text-center">
                    <ButtonComponent />
                </div>
            </div>
          </div>
      </section>
    )
  }
}

export default BenefitsOf
